@import url('https://fonts.googleapis.com/css2?family=Bellota+Text:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Manrope:wght@200;300;400;500;600;700;800&family=Sora:wght@100;200;300;400;500;600;700;800&display=swap');

:root {
  --cl: 1;
  --hue: 194;
  --fg: hsl(var(--hue), 10%, 0%);
  --primary: hsl(var(--hue), 90%, 55%);
  --primaryT: hsla(var(--hue), 90%, 55%, 0);
  --transDur: 0.3s;

}
body {
	font-family: 'Sora', sans-serif;
}
.btn_main_color {
	/* background: #fff */
}

.landing {
  background: url('../images/land.png') no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

}

.active_side {
	position: relative;
width: 8px;
height: 36px;
right: -22px;
top: 0px;

/* blue 1 */

background: #00C2FF;
border-radius: 6px 0px 0px 6px;
}

.active_mobile {
	position: absolute;
	width: 8px;
	height: 58px;

	top: -80px;
	right: 45%;
	/* blue 1 */
	
	background: #00C2FF;
	border-radius: 6px 0px 0px 6px;
	transform: rotate(-90deg);
}

.preloader {
  align-items: center;
	
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  transition: opacity 0.2s linear;
  width: 100%;
  z-index: 9999;
  opacity: 1;
  transform: opacity 1s linear;
}

.preloader_2 {
  align-items: center;
	
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  transition: opacity 0.2s linear;
  width: 100%;
  z-index: 9999;
  opacity: 1;
  transform: opacity 1s linear;
}

.lds-default {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-default div {
  position: absolute;
  width: 6px;
  height: 6px;
  background: #fff;
  border-radius: 50%;
  animation: lds-default 1.2s linear infinite;
}
.lds-default div:nth-child(1) {
  animation-delay: 0s;
  top: 37px;
  left: 66px;
}
.lds-default div:nth-child(2) {
  animation-delay: -0.1s;
  top: 22px;
  left: 62px;
}
.lds-default div:nth-child(3) {
  animation-delay: -0.2s;
  top: 11px;
  left: 52px;
}
.lds-default div:nth-child(4) {
  animation-delay: -0.3s;
  top: 7px;
  left: 37px;
}
.lds-default div:nth-child(5) {
  animation-delay: -0.4s;
  top: 11px;
  left: 22px;
}
.lds-default div:nth-child(6) {
  animation-delay: -0.5s;
  top: 22px;
  left: 11px;
}
.lds-default div:nth-child(7) {
  animation-delay: -0.6s;
  top: 37px;
  left: 7px;
}
.lds-default div:nth-child(8) {
  animation-delay: -0.7s;
  top: 52px;
  left: 11px;
}
.lds-default div:nth-child(9) {
  animation-delay: -0.8s;
  top: 62px;
  left: 22px;
}
.lds-default div:nth-child(10) {
  animation-delay: -0.9s;
  top: 66px;
  left: 37px;
}
.lds-default div:nth-child(11) {
  animation-delay: -1s;
  top: 62px;
  left: 52px;
}
.lds-default div:nth-child(12) {
  animation-delay: -1.1s;
  top: 52px;
  left: 62px;
}
@keyframes lds-default {
  0%, 20%, 80%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
}


/* laod */
.ring{
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  width:150px;
  height:150px;
  background:transparent;
  border:3px solid #3c3c3c;
  border-radius:50%;
  text-align:center;
  line-height:150px;
  font-family:sans-serif;
  font-size:20px;
  color:#fff000;
  letter-spacing:4px;
  text-transform:uppercase;
  text-shadow:0 0 10px #fff000;
  box-shadow:0 0 20px rgba(0,0,0,.5);
}
.ring:before{
  content:'';
  position:absolute;
  top:0px;
  left:0px;
  width:100%;
  height:100%;
  border:3px solid transparent;
  border-top:3px solid #fff000;
  border-right:3px solid #fff000;
  border-radius:50%;
  animation:animateC 2s linear infinite;
}
.ring span
{
  display:block;
  position:absolute;
  top:calc(50% - 2px);
  left:50%;
  width:50%;
  height:4px;
  background:transparent;
  transform-origin:left;
  animation:animate 2s linear infinite;
}
.ring span:before
{
  content:'';
  position:absolute;
  width:16px;
  height:16px;
  border-radius:50%;
  background:#fff000;
  top:-5px;
  right:-8px;
  box-shadow:0 0 20px #fff000;
}

.ring-li{
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  width:150px;
  height:150px;
  background:transparent;
  border:3px solid #3c3c3c;
  border-radius:50%;
  text-align:center;
  line-height:150px;
  font-family:sans-serif;
  font-size:20px;
  color:#000;
  letter-spacing:4px;
  text-transform:uppercase;
  text-shadow:0 0 10px #00c2ff;
  box-shadow:0 0 20px rgba(0,0,0,.5);
}
.ring-li:before{
  content:'';
  position:absolute;
  top:0px;
  left:0px;
  width:100%;
  height:100%;
  border:3px solid transparent;
  border-top:3px solid #00c2ff;
  border-right:3px solid #00c2ff;
  border-radius:50%;
  animation:animateC 2s linear infinite;
}
.ring-li span
{
  display:block;
  position:absolute;
  top:calc(50% - 2px);
  left:50%;
  width:50%;
  height:4px;
  background:transparent;
  transform-origin:left;
  animation:animate 2s linear infinite;
}
.ring-li span:before
{
  content:'';
  position:absolute;
  width:16px;
  height:16px;
  border-radius:50%;
  background:#00c2ff;
  top:-5px;
  right:-8px;
  box-shadow:0 0 20px #00c2ff;
}



@keyframes animateC
{
  0%
  {
    transform:rotate(0deg);
  }
  100%
  {
    transform:rotate(360deg);
  }
}
@keyframes animate
{
  0%
  {
    transform:rotate(45deg);
  }
  100%
  {
    transform:rotate(405deg);
  }
}


/* Hide scrollbar for Chrome, Safari and Opera */
.no-bar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-bar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}


.dropdown-container  {
  background-color: inherit !important;
  border-radius: inherit !important;
  border: inherit !important;
}

.dropdown-content { 
  color: #000 !important;
  /* z-index: 50000 !important; */
  /* height: 150px !important; */
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.ant-image {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

::-webkit-calendar-picker-indicator {
  filter: invert(var(--cl));
  /* display: none; */
  /* opacity: 0; */
}

.react-joyride__spotlight {
  /* background-color: rgba(128, 128, 128, 0.5) !important; */
}

.faq_msg {
  visibility: hidden;
  opacity: 0;

  /* transition: visibility 0s linear 300ms, opacity 300ms; */

}

.faq_active {
  visibility: visible;
  animation: 1s fadeIn;
  animation-fill-mode: forwards;

  /* opacity: 1; */
  
  /* transition: visibility 0s linear 0s, opacity 300ms; */
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    visibility: visible;
    opacity: 1;
  }
}

.sd-container {
  position: relative;
  float: left;
}

.sd {
  padding: 5px 10px;
  height: 30px;
  width: 150px;
}

.open-button {
  position: absolute;
  top: 10px;
  right: 11px;
  width: 25px;
  height: 25px;
  background: #fff;
  pointer-events: none;
}

.open-button button {
  border: none;
  background: transparent;
}

input[type=range]::-webkit-slider-thumb {
	pointer-events: all;
	width: 24px;
	height: 24px;
	-webkit-appearance: none;
  
/* @apply w-6 h-6 appearance-none pointer-events-auto; */
}

.se-option {
  display: flex;
  flex-direction: row;
  align-items: center;
}

/* toggle */


/* Default */
.theme {
  display: flex;
  align-items: center;
  -webkit-tap-highlight-color: transparent;
}
.theme__fill,
.theme__icon {
  pointer-events: none;
  transition: transform var(--transDur) ease-in-out;
}
.theme__fill {
  /* background-color: var(--bg); */
  display: block;
  mix-blend-mode: difference;
  position: fixed;
  inset: 0;
  height: 100%;
  transform: translateX(-100%);
}
.theme__icon,
.theme__toggle {
  z-index: 1;
}
.theme__icon,
.theme__icon-part {
  position: absolute;
}
.theme__icon {
  display: block;
  top: 2.2em;
  left: 0em;
  width: 1.5em;
  height: 1.5em;
}
.theme__icon-part {
  border-radius: 50%;
  box-shadow: 0.4em -0.4em 0 0.5em hsl(0, 0%, 100%) inset;
  top: calc(50% - 0.5em);
  left: calc(50% - 0.5em);
  width: 1em;
  height: 1em;
  transition: box-shadow var(--transDur) ease-in-out,
    opacity var(--transDur) ease-in-out, transform var(--transDur) ease-in-out;
  transform: scale(0.5);
}
.theme__icon-part ~ .theme__icon-part {
  background-color: hsl(0, 0%, 100%);
  border-radius: 0.05em;
  box-shadow: none;
  top: 50%;
  left: calc(50% - 0.05em);
  transform: rotate(0) translateY(0.5em);
  transform-origin: 50% 0;
  width: 0.1em;
  height: 0.2em;
}
.theme__icon-part:nth-child(3) {
  transform: rotate(45deg) translateY(0.5em);
}
.theme__icon-part:nth-child(4) {
  transform: rotate(90deg) translateY(0.5em);
}
.theme__icon-part:nth-child(5) {
  transform: rotate(135deg) translateY(0.5em);
}
.theme__icon-part:nth-child(6) {
  transform: rotate(180deg) translateY(0.5em);
}
.theme__icon-part:nth-child(7) {
  transform: rotate(225deg) translateY(0.5em);
}
.theme__icon-part:nth-child(8) {
  transform: rotate(270deg) translateY(0.5em);
}
.theme__icon-part:nth-child(9) {
  transform: rotate(315deg) translateY(0.5em);
}
.theme__label,
.theme__toggle,
.theme__toggle-wrap {
  position: relative;
}
.theme__toggle,
.theme__toggle::before {
  display: block;
}
.theme__toggle {
  background-color: hsl(48, 90%, 85%);
  border-radius: 25% / 50%;

  border :1px solid #00c2ff;
  box-shadow: 0 0 0 0.125em var(--primaryT);
  padding: 0.25em;
  width: 6em;
  height: 3em;
  appearance: none;
  -webkit-appearance: none;
  cursor: pointer;
  transition: background-color var(--transDur) ease-in-out,
    box-shadow 0.15s ease-in-out, transform var(--transDur) ease-in-out;
}
.theme__toggle::before {
  background-color: hsl(48, 90%, 55%);
  border-radius: 50%;
  content: '';
  width: 2.5em;
  height: 2.5em;
  transition: background-color var(--transDur) ease-in-out,
    transform var(--transDur) ease-in-out;
}
.theme__toggle:focus {
  box-shadow: 0 0 0 0.125em var(--primary);
  outline: transparent;
}
/* Checked */
.theme__toggle:checked {
  background-color: hsl(198, 90%, 15%);
}
.theme__toggle:checked::before,
.theme__toggle:checked ~ .theme__icon {
  transform: translateX(3em);
}
.theme__toggle:checked::before {
  background-color: hsl(198, 90%, 55%);
}
.theme__toggle:checked ~ .theme__fill {
  transform: translateX(0);
}
.theme__toggle:checked ~ .theme__icon .theme__icon-part:nth-child(1) {
  transform: scale(1);
  box-shadow: 0.2em -0.2em 0 0.2em hsl(0, 0%, 100%) inset;
}
.theme__toggle:checked ~ .theme__icon .theme__icon-part ~ .theme__icon-part {
  opacity: 0;
}
.theme__toggle:checked ~ .theme__icon .theme__icon-part:nth-child(2) {
  transform: rotate(45deg) translateY(0.8em);
}
.theme__toggle:checked ~ .theme__icon .theme__icon-part:nth-child(3) {
  transform: rotate(90deg) translateY(0.8em);
}
.theme__toggle:checked ~ .theme__icon .theme__icon-part:nth-child(4) {
  transform: rotate(135deg) translateY(0.8em);
}
.theme__toggle:checked ~ .theme__icon .theme__icon-part:nth-child(5) {
  transform: rotate(180deg) translateY(0.8em);
}
.theme__toggle:checked ~ .theme__icon .theme__icon-part:nth-child(6) {
  transform: rotate(225deg) translateY(0.8em);
}
.theme__toggle:checked ~ .theme__icon .theme__icon-part:nth-child(7) {
  transform: rotate(270deg) translateY(0.8em);
}
.theme__toggle:checked ~ .theme__icon .theme__icon-part:nth-child(8) {
  transform: rotate(315deg) translateY(0.8em);
}
.theme__toggle:checked ~ .theme__icon .theme__icon-part:nth-child(9) {
  transform: rotate(360deg) translateY(0.8em);
}
.theme__toggle-wrap {
  margin: 0 0.75em;
}


/* Hide scrollbar for Chrome, Safari and Opera */
.drawer-side::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.drawer-side {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}


